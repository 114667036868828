<template>
  <div>
    <Cascader :data="parentLevelData" v-model="parentDate" />
  </div>
</template>

<script>
import {
  getMenusCascaderApi
} from '@/api/system'
export default {
  name: 'parentMenus',
  data () {
    return {
      parentId: 0,
      // 父级数据
      parentDate: [],
      // 联级数据
      parentLevelData: []
    }
  },
  methods: {
    // 获取联级数据
    getMenusCascaderRequest () {
      getMenusCascaderApi(this.parentId).then(res => {
        this.parentLevelData = res.data.list
        this.parentDate = res.data.data
      }).catch(err => {
        console.log('获取联级数据失败：' + err)
      })
    }
  }
}
</script>

<style scoped>

</style>
